/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */

:root {
  --rojo: #ff6347;
  --verde: #28a745;
  --azul: #007bff;
  --amarillo: #ffcc00;
  --blancoRoto: #f8f9fa;
  --grisClaro: #6c757d;
  --grisPizarra: #343a40;
  --grisOscuro: #1a1a1a;
}


body {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.47059em;
  letter-spacing: .01em;
  color: var(--grisOscuro);
  /* background-color: var(--blancoRoto) !important; */
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/* CUERPO */
.App.container {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  background-color: white !important;
}

/* HEADER */
.header-decorative {
  background-color: var(--blancoRoto);
  /* padding: 20px 0; */
  position: relative;
  overflow: hidden;
  background-image: url(https://i.ibb.co/hF9zYvN/Proyecto-nuevo-3.png);
  background-repeat: repeat-x;
  background-size: 34%;
  background-position: top;
}

/* TÍTULOS HEADER Y FOOTER */
.header-decorative h2 {
  font-family: 'Arial Black', sans-serif;
  font-size: 3rem;
  color: var(--rojo);
  margin: 0;
}

.header-decorative h3 {
  font-family: 'Arial Black', sans-serif;
  font-size: 2rem;
  color: var(--rojo);
  margin: 0;
}



/* LISTITEM */
h4 {
  font-family: 'Arial Black', sans-serif;
}

.price {
  font-size: 3rem;
  font-weight: bold;
}

p {
  margin-bottom: 0 !important;
}

.list-unstyled li:not(:last-child) {
  border-bottom: 1px dashed var(--rojo) !important;
}





/* FOOTER */
.backFooter {
  background-color: var(--blancoRoto);
}


.backFooter h2 {
  font-family: 'Arial Black', sans-serif;
  font-size: 2.5rem;
  color: var(--rojo);
  text-align: center;
  margin: 0;
}

.backFooter h3 {
  font-family: 'Arial Black', sans-serif;
  font-size: 1.7rem;
  color: var(--rojo);
  text-align: center;
  margin: 0;
}




/* ul {
  padding-left: 0;
  margin: 0 10px;
} */








.nav-pills .nav-link.active {
  background-color: var(--rojo) !important;
  /* Color del enlace activo */
}

.menu-section.d-none {
  display: none;
}

.imgProduct {
  width: 36%;
  float: right;
}


.btn-flotante {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: var(--rojo) !important;
}

footer {
  background-image: url(https://i.ibb.co/hF9zYvN/Proyecto-nuevo-3.png);
  background-repeat: repeat-x;
  background-size: 33%;
  background-position: bottom;
}


.nav-link.highlight {
  background-color: var(--amarillo);
  /* Fondo para Novedades */
  color: black;
  margin-right: 5%;
  font-weight: bold;
  border-radius: 5px;
}

.nav-link.special-highlight {
  background-color: var(--verde);
  /* Fondo para Encargo */
  color: white;
  font-weight: bold;
  border-radius: 5px;
}


.menu-section {
  padding: 20px;
  /* background-color: var(--blancoRoto); */
}

.menu-card {
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* padding: 12px;
  margin-bottom: 20px; */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.menu-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.menu-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: var(--azul);
  padding: 0.5rem !important;
  margin: 0 !important;
}

.list-unstyled {
  padding: 0;
  margin: 0;
}



.list-unstyled li h4 {
  font-size: 1.2rem;
  color: var(--grisPizarra);
}

.list-unstyled li p {
  font-size: 0.9rem;
  color: var(--grisClaro);
}

.list-unstyled li .price {
  font-size: 1rem;
  font-weight: bold;
  color: var(--verde);
  /* text-align: right; */
}

.floating-buttons {
  position: fixed;
  bottom: 20px;
  right: 20%;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.floating-buttons .btn {
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-decoration: none;
  color: white;
}

.floating-buttons .btn-primary {
  background-color: var(--azul);
}

.floating-buttons .btn-secondary {
  background-color: var(--grisClaro);
}

.floating-buttons .btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.contenedorBTN {
  width: 100%;
}